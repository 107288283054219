<template>
  <div id="parkingGroupRecord">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>停车记录管理</el-breadcrumb-item>
      <el-breadcrumb-item>停车记录列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">输入关键字:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
            </el-input>
          </li>
          <li>
            <span class="label">联合停车场:</span>
            <el-select
              v-model="filtrate.parkingGroupId"
              placeholder="请选择"
              clearable
              @change="getList(1)"
            >
              <el-option
                v-for="item in parkingGroupList"
                :key="item.parkingGroupId"
                :label="item.groupName"
                :value="item.parkingGroupId"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <span class="label">停车状态:</span>
            <el-select
              v-model="filtrate.parkingStatus"
              placeholder="请选择"
              clearable
              @change="getList(1)"
            >
              <el-option
                v-for="item in select_parkingStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <span class="label">开始时间:</span>
            <el-date-picker
              v-model="filtrate.startTime"
              type="datetime"
              placeholder="选择开始时间"
              @change="getList(1)"
            >
            </el-date-picker>
          </li>
          <li>
            <span class="label">结束时间:</span>
            <el-date-picker
              v-model="filtrate.endTime"
              type="datetime"
              placeholder="选择结束时间"
              @change="getList(1)"
            >
            </el-date-picker>
          </li>
          <ul>
            <li>
              <el-button type="primary" size="medium" @click="getList(1)"
                >查询
              </el-button>
            </li>
            <li>
              <el-button class="resetBtn" size="medium" @click="reset"
                >重置
              </el-button>
            </li>
          </ul>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
        row-key="id"
        lazy
        :load="load"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        :key="table.loading"
      >
        <el-table-column prop="groupName" label="联合停车场" align="center">
        </el-table-column>
        <el-table-column prop="parkingLotName" label="停车场" align="center">
        </el-table-column>
        <el-table-column label="车牌号1" align="center">
          <template #default="scope">
            <span :class="{ differentPlateNum: !scope.row.isSamePlateNum }">{{
              scope.row.plateNum
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="车牌号2" align="center">
          <template #default="scope">
            <span :class="{ differentPlateNum: !scope.row.isSamePlateNum }">{{
              scope.row.plateExtNum
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="unlicensed" label="无牌车" align="center">
        </el-table-column>
        <el-table-column prop="inChannelName" label="入口" align="center">
        </el-table-column>
        <el-table-column prop="arrivalTime" label="入场时间" align="center">
        </el-table-column>
        <el-table-column label="入场图片" align="center">
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.arrivalImage"
              style="width: 70px; height: 70px"
              :src="scope.row.arrivalImage"
              fit="contain"
              :preview-src-list="[scope.row.arrivalImage]"
            ></el-image>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column prop="outChannelName" label="出口" align="center">
        </el-table-column>
        <el-table-column prop="departureTime" label="出场时间" align="center">
        </el-table-column>
        <el-table-column label="出场图片" align="center">
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.departureImage"
              style="width: 70px; height: 70px"
              :src="scope.row.departureImage"
              fit="contain"
              :preview-src-list="[scope.row.departureImage]"
            ></el-image>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column prop="departureType" label="离场类型" align="center">
        </el-table-column>
        <el-table-column prop="parkingStatus" label="停车状态" align="center">
        </el-table-column>
        <el-table-column prop="parkingTime" label="停车时长" align="center">
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    parkingLotList() {
      return this.$store.state.select.parkingLotList;
    },
  },
  data() {
    return {
      parkingGroupList: [],
      filtrate: {
        keyword: "",
        parkingStatus: "",
        parkingGroupId: "",
        startTime: this.$moment().subtract(14, "days"),
        endTime: new Date(),
      },
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
    };
  },
  created() {
    this.getParkingGroup();
    this.getList();
  },
  methods: {
    async getParkingGroup() {
      try {
        let res = await this.$http.get("/parking/group/list/all");
        if (res.code === 0) {
          this.parkingGroupList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
    reset() {
      this.filtrate = {
        keyword: "",
        parkingStatus: "",
        parkingGroupId: "",
        startTime: "",
        endTime: "",
      };
      this.getList(1);
    },
    async load(tree, treeNode, resolve) {
      let url = `/parking/record/list`;
      let params = {
        pageSize: 999,
        parkingGroupRecordId: tree.parkingGroupRecordId,
      };
      let methods = "post";
      if (treeNode.level !== 0) {
        url = `/parking/record/list/${tree.parkingRecordId}/child`;
        params = {};
        methods = "get";
      }
      let res = await this.$http[methods](url, params);
      if (res.code === 0) {
        let list = treeNode.level === 0 ? res.data.list : res.data;
        for (let item of list) {
          item.hasChildren = item.hasChildren === "是";
          item.id = item.parkingRecordId;
          item.inChannelName = item.inChannelName ? item.inChannelName : "--";
          item.outChannelName = item.outChannelName
            ? item.outChannelName
            : "--";
          item.departureType = item.departureType ? item.departureType : "--";
          item.departureTime = item.departureTime
            ? this.$moment(item.departureTime).format("yyyy-MM-DD HH:mm:ss")
            : "--";
          item.arrivalTime = item.arrivalTime
            ? this.$moment(item.arrivalTime).format("yyyy-MM-DD HH:mm:ss")
            : "--";
          if (!item.plateNum || !item.plateExtNum) {
            item.isSamePlateNum = true;
          } else {
            item.isSamePlateNum = item.plateNum === item.plateExtNum;
          }
        }
        resolve(list);
      } else {
        this.$message.error(res.msg);
      }
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/parking/record/list/group", {
          pageNum: page,
          pageSize: this.table.pageSize,
          ...this.filtrate,
          parkingStatus:
            this.filtrate.parkingStatus === ""
              ? null
              : this.filtrate.parkingStatus,
          parkingGroupId:
            this.filtrate.parkingGroupId === ""
              ? null
              : this.filtrate.parkingGroupId,
        });
        if (res.code === 0) {
          for (let item of res.data.list) {
            item.hasChildren = true;
            item.id = item.parkingGroupRecordId;
            item.inChannelName = item.inChannelName ? item.inChannelName : "--";
            item.outChannelName = item.outChannelName
              ? item.outChannelName
              : "--";
            item.departureType = item.departureType ? item.departureType : "--";
            item.departureTime = item.serviceEndTime
              ? this.$moment(item.serviceEndTime).format("yyyy-MM-DD HH:mm:ss")
              : "--";
            item.arrivalTime = item.serviceStartTime
              ? this.$moment(item.serviceStartTime).format(
                  "yyyy-MM-DD HH:mm:ss"
                )
              : "--";
            if (!item.plateNum || !item.plateExtNum) {
              item.isSamePlateNum = true;
            } else {
              item.isSamePlateNum = item.plateNum === item.plateExtNum;
            }
          }
          this.table.tableData = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#parkingGroupRecord {
  .el-table {
    .el-table__body-wrapper {
      .el-table__body {
        tbody {
          .el-table__row {
            .cell {
              .el-image {
                width: 53px;
                height: 38px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
